import { Instance, types } from 'mobx-state-tree';
import User from '@/store/user/models/User';

const Unit = types.model({
  id: types.maybeNull(types.string),
  unitId: types.maybeNull(types.string),
  identifier: types.maybeNull(types.string),
  identifierDisplay: types.maybeNull(types.string),
  userData: types.optional(types.array(User), []),
  totalAssociatedUsers: types.maybeNull(types.number),
  defaultUser: types.maybeNull(types.string),
  feeId: types.maybeNull(types.string),
  feeName: types.maybeNull(types.string),
  latestPayment: types.maybeNull(types.string),
  latestPaymentOutsideCqurity: types.maybeNull(types.string),
  overdueAmount: types.maybeNull(types.number),
});

export interface IUnit extends Instance<typeof Unit> {}
export default Unit;
