import { getMessaging, onMessage } from 'firebase/messaging';
import debug from 'debug';
import useStore from '@/hooks/useStore';

import { codes } from '@/constants/notificationCodes';

const log = debug('hook:useNotificationListeners:log');

export const useNotificationListeners = () => {
  const { accessStore } = useStore();
  const { getSetAccessList, flags } = accessStore;

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    const code = payload?.data?.code;

    switch (code) {
      case codes.NEW_ASSIGNED_ACCESS:
        log('Code: NEW_ASSIGNED_ACCESS received');
        getSetAccessList();
        break;
      case codes.SOFT_ACCESS_DELETED:
        log('Code: SOFT_ACCESS_DELETED received');
        getSetAccessList();
        break;
      case codes.ACCESS_VISIT_IN:
        log('Code: ACCESS_VISIT_IN received');
        flags.updateFlag('mustFetchAccessLogList', true);
        setTimeout(() => {
          flags.updateFlag('isValidatingIn', '2');
          flags.updateFlag('isValidatingInOut', '2');
        }, 2000);
        break;
      case codes.ACCESS_VISIT_OUT:
        log('Code: ACCESS_VISIT_OUT received');
        flags.updateFlag('mustFetchAccessLogList', true);
        setTimeout(() => {
          flags.updateFlag('isValidatingOut', '2');
          flags.updateFlag('isValidatingInOut', '2');
        }, 2000);
        break;
      case codes.ACCESS_SERVICE_IN:
        log('Code: ACCESS_VISIT_IN received');
        flags.updateFlag('mustFetchAccessLogList', true);
        break;
      case codes.ACCESS_SERVICE_OUT:
        log('Code: ACCESS_VISIT_OUT received');
        flags.updateFlag('mustFetchAccessLogList', true);
        break;
      default:
        break;
    }
  });

  return null;
};
