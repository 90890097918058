import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useTabContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    indicator: {
      width: 6,
      backgroundColor: theme.cqTheme.arctic,
      left: 0,
    },
  }),
);

export const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
      width: '100%',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: 14,
      color: theme.cqTheme.charcoal,
      opacity: 1,
      '& svg': {
        fontSize: 24,
        marginRight: theme.spacing(2),
        fill: theme.cqTheme.charcoal,
        color: 'inherit',
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        minWidth: 50,
        width: 'auto',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    selected: {
      fontWeight: 600,
      color: theme.cqTheme.bottle,
    },
    labelIcon: {
      '& $wrapper > *:first-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 50,
      },
    },
  }),
);
