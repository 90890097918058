import { types, applySnapshot, getSnapshot, Instance, flow, cast } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import Reservation from './models/Reservation';

const log = debug('store:reservations:log');
const errorLog = debug('store:reservations:error');

const ReservationsStore = types
  .model({
    flags: types.optional(Flags, {}),
    reservationsSetList: types.optional(types.array(Reservation), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    getReservationsSetList: flow(function* () {
      log('getReservationsSetList >>>>');
      try {
        self.flags.isFetchingGetSetReservationsList = true;
        const response = yield axios.get(endpoints.getReservationsSetList);
        log('getReservationsSetList >>>>', response);
        if (response.data.success) {
          self.reservationsSetList = cast(response.data.reservationsList);
        }
      } catch (err) {
        errorLog('getReservationsSetList >>>>', err);
      } finally {
        self.flags.isFetchingGetSetReservationsList = false;
      }
    }),
  }))
  .actions((self) => ({
    approveReservation: flow(function* (reservationId) {
      log('approveReservation >>>>', reservationId);
      try {
        self.flags.isFetchingApproveReservation = true;
        const response = yield axios.patch(endpoints.approveReservation(reservationId));
        log('approveReservation >>>>', response);
        if (response.data.success) {
          self.getReservationsSetList();
        }
        return response.data;
      } catch (err) {
        errorLog('approveReservation >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingApproveReservation = false;
      }
    }),
    rejectReservation: flow(function* (reservationId) {
      log('rejectReservation >>>>', reservationId);
      try {
        self.flags.isFetchingRejectReservation = true;
        const response = yield axios.patch(endpoints.rejectReservation(reservationId));
        log('rejectReservation >>>>', response);
        if (response.data.success) {
          self.getReservationsSetList();
        }
        return response.data;
      } catch (err) {
        errorLog('rejectReservation >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingRejectReservation = false;
      }
    }),
  }));

export interface IReservationsStore extends Instance<typeof ReservationsStore> {}

export default ReservationsStore;
