import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingValidateAccess: false,
    isFetchingValidateAccessSuccess: false,
    isFetchingValidateAccessError: false,
    validateAccessErrorMsg: '',
    isFetchingAccessList: false,
    isFetchAccessLogList: false,
    isValidatingIn: '',
    isValidatingOut: '',
    isValidatingInOut: '',
    mustFetchAccessLogList: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
