enum allowedRoles {
  ADMIN = 'admin',
  USER_BASIC = 'userBasic',
  SURVEILLANCE = 'surveillance',
}

enum allowedRolesDisplayEs {
  admin = 'Administrador',
  userBasic = 'Usuario',
  surveillance = 'Vigilancia',
}

export { allowedRoles, allowedRolesDisplayEs };
