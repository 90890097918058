import { Instance, types } from 'mobx-state-tree';

const Set = types.model({
  setId: types.maybeNull(types.string),
  setName: types.maybeNull(types.string),
  setUnits: types.maybeNull(types.string),
  setType: types.maybeNull(types.string),
  setUnitId: types.maybeNull(types.string),
  setUnitIdentifier: types.maybeNull(types.string),
  setUnitIdentifierDisplay: types.maybeNull(types.string),
  setUnitFeeId: types.maybeNull(types.string),
});

export interface ISet extends Instance<typeof Set> {}
export default Set;
