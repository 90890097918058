import { Instance, types } from 'mobx-state-tree';

const Fee = types.model({
  id: types.string,
  feeId: types.string,
  name: types.string,
  amount: types.number,
  active: types.boolean,
  status: types.string,
  isDynamic: types.boolean,
  condition: types.string,
  conditionDays: types.number,
  conditionAmount: types.number,
  isDefault: types.boolean,
  limitDays: types.number,
});

export interface IFee extends Instance<typeof Fee> {}
export default Fee;
