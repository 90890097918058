import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingUserData: false,
    isFetchingAddAssociatedUser: false,
    fetchingAddAssociatedUserSuccess: false,
    fetchingAddAssociatedUserError: false,
    isFetchingResendAssociatedUserInvitation: false,
    isFetchingCancelAssociatedUserInvitation: false,
    isFetchingUpdateAssociatedUser: false,
    isFetchingSoftDeleteUser: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
