import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import Access from './models/Access';
import Log from './models/Log';

const log = debug('store:access:log');
const errorLog = debug('store:access:error');

const AccessStore = types
  .model({
    flags: types.optional(Flags, {}),
    accessList: types.optional(types.array(Access), []),
    preFilteredAccessList: types.optional(types.array(Access), []),
    accessLogList: types.optional(types.array(Log), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .views((self) => ({
    get getAccessList() {
      return [...self.accessList];
    },
    get getPreFilteredAccessList() {
      return [...self.preFilteredAccessList];
    },
  }))
  .actions((self) => ({
    validateAccess: flow(function* ({ accessId, action }) {
      const { accessStore } = getRoot(self);
      log('validateAccess >>>>', accessId, action);
      self.flags.isFetchingValidateAccessError = false;
      self.flags.isFetchingValidateAccessSuccess = false;
      try {
        self.flags.isFetchingValidateAccess = true;
        const response = yield axios.get(endpoints.validateAccess(accessId, action));
        log('validateAccess >>>>', response);
        if (response.data.success) {
          self.flags.isFetchingValidateAccessSuccess = true;
          yield accessStore.getSetAccessList();
        }
        return response.data;
      } catch (err: any) {
        errorLog('validateAccess >>>>', err);
        self.flags.isFetchingValidateAccessError = true;
        self.flags.validateAccessErrorMsg = err?.msg;
        throw err;
      } finally {
        self.flags.isFetchingValidateAccess = false;
      }
    }),
    getSetAccessList: flow(function* () {
      log('getSetAccessList >>>>');
      try {
        self.flags.isFetchingAccessList = true;
        const response = yield axios.get(endpoints.getSetAccessList);
        log('getSetAccessList >>>>', response);
        if (response.data.success) {
          self.accessList = cast(response.data.accessList);
        }
      } catch (err) {
        errorLog('getSetAccessList >>>>', err);
      } finally {
        self.flags.isFetchingAccessList = false;
      }
    }),
    getSetAccessLogList: flow(function* (date: string) {
      log('getSetAccessLogList >>>>');
      try {
        self.flags.isFetchAccessLogList = true;
        const response = yield axios.post(endpoints.getSetAccessLogList, { date: date });
        log('getSetAccessLogList >>>>', response);
        if (response.data.success) {
          self.accessLogList = cast(response.data.accessLogList);
        }
      } catch (err) {
        errorLog('getSetAccessLogList >>>>', err);
      } finally {
        self.flags.isFetchAccessLogList = false;
      }
    }),
    filterAccess: (value: string) => {
      if (value === '' && Object.keys(toJS(self.preFilteredAccessList)).length > 0) {
        self.accessList = cast(toJS(self.preFilteredAccessList));
        self.preFilteredAccessList = cast([]);
      }

      if (Object.keys(toJS(self.preFilteredAccessList)).length === 0) {
        self.preFilteredAccessList = cast(toJS(self.accessList));
      }

      const filtered = toJS(self.accessList).filter(function (v) {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v.hostSetUnitIdentifier.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v.guestName.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      self.accessList = cast(filtered);
    },
  }));

export interface IAccessStore extends Instance<typeof AccessStore> {}

export default AccessStore;
