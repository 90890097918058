import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import PaymentList from './models/PaymentList';
import Payment from './models/Payment';
import PaymentsTotals from './models/PaymentsTotals';

const log = debug('store:payments:log');
const errorLog = debug('store:payments:error');

const PaymentsStore = types
  .model({
    flags: types.optional(Flags, {}),
    paymentsTotals: types.maybeNull(PaymentsTotals),
    paymentsList: types.optional(types.array(PaymentList), []),
    paymentDetail: types.maybeNull(Payment),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    getPaymentsList: flow(function* (year: string) {
      log('getPaymentsList >>>>', year);
      try {
        self.flags.isFetchingGetSetPaymentList = true;
        const response = yield axios.get(endpoints.getPaymentsList(year));
        log('getPaymentsList >>>>', response);
        if (response.data.success) {
          self.paymentsList = cast(response.data.setPaymentList);
          self.paymentsTotals = cast(response.data.setPaymentsTotals);
        }
      } catch (err) {
        errorLog('getPaymentsList >>>>', err);
      } finally {
        self.flags.isFetchingGetSetPaymentList = false;
      }
    }),
  }))
  .actions((self) => ({
    getPaymentDetail: flow(function* (paymentId: string) {
      log('getPaymentDetail >>>>', paymentId);
      try {
        self.flags.isFetchingGetPaymentDetail = true;
        const response = yield axios.get(endpoints.getPaymentDetail(paymentId));
        log('getPaymentDetail >>>>', response);
        if (response.data.success) {
          self.paymentDetail = cast(response.data.paymentData);
        }
      } catch (err) {
        errorLog('getPaymentDetail >>>>', err);
      } finally {
        self.flags.isFetchingGetPaymentDetail = false;
      }
    }),
    registerCashPayment: flow(function* (paymentData) {
      log('registerCashPayment >>>>', paymentData);
      try {
        self.flags.isFetchingRegisterCashPayment = true;
        const response = yield axios.post(endpoints.registerCashPayment, paymentData);
        log('getPaymentDetail >>>>', response);
        if (response.data.success) {
          self.getPaymentsList(paymentData.payedYear);
        }
        return response.data;
      } catch (err) {
        errorLog('registerCashPayment >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingRegisterCashPayment = false;
      }
    }),
  }));

export interface IPaymentsStore extends Instance<typeof PaymentsStore> {}

export default PaymentsStore;
