import { Instance, types } from 'mobx-state-tree';

const Reservation = types.model({
  id: types.string,
  reservationId: types.string,
  commonAreaId: types.string,
  reservationDate: types.string,
  status: types.string,
  createdAt: types.string,
  commonAreaName: types.string,
  hostUserFirstName: types.string,
  hostUserMiddleName: types.string,
  hostUserLastName: types.string,
  hostUserFullName: types.string,
  unitId: types.string,
  identifier: types.string,
  identifierDisplay: types.string,
});

export interface IReservation extends Instance<typeof Reservation> {}
export default Reservation;
