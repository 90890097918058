import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingGetCommonAreaList: false,
    fetchGetCommonAreaListSuccess: false,
    fetchGetCommonAreaListError: false,
    isFetchingCreateCommonArea: false,
    isFetchingUpdateCommonArea: false,
    isFetchingSoftDeleteCommonArea: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
