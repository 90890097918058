import { Instance, types } from 'mobx-state-tree';

const AssociatedUser = types.model({
  userId: types.maybeNull(types.string),
  userFirstName: types.maybeNull(types.string),
  userMiddleName: types.maybeNull(types.string),
  userLastName: types.maybeNull(types.string),
  userFullName: types.maybeNull(types.string),
  userPhone: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  isActive: types.optional(types.boolean, false),
  emailVerified: types.optional(types.boolean, false),
});

export interface IAssociatedUser extends Instance<typeof AssociatedUser> {}
export default AssociatedUser;
