import { Instance, types } from 'mobx-state-tree';

const CommonArea = types.model({
  id: types.string,
  commonAreaId: types.string,
  name: types.string,
  active: types.number,
  status: types.string,
});

export interface ICommonArea extends Instance<typeof CommonArea> {}
export default CommonArea;
