import { createTheme } from '@mui/material/styles';
import { cqTheme } from '@/config/theme/index';

const mui5Theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 4px 20px rgba(1, 70, 81, 0.08)',
        },
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cqTheme,
  palette: {
    primary: {
      main: cqTheme.bottle,
      contrastText: cqTheme.cotton,
    },
    secondary: {
      main: cqTheme.mint,
      light: cqTheme.cotton,
      contrastText: cqTheme.dolphin,
    },
    background: {
      paper: cqTheme.cotton,
      default: cqTheme.pearl,
      // dark: oysterTheme.chocolate,
    },
    success: {
      main: cqTheme.cq,
    },
    error: {
      main: cqTheme.ferrari,
      light: cqTheme.salmon,
      dark: cqTheme.merlot,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
    h2: {
      fontSize: 40,
      fontWeight: 'bold',
      margin: 0,
      color: cqTheme.charcoal,
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
      margin: 0,
      color: cqTheme.charcoal,
    },
    h4: {
      fontSize: 16,
      fontWeight: 'normal',
      margin: 0,
      color: cqTheme.charcoal,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      margin: 0,
      color: cqTheme.obsidian,
    },
    h6: {
      fontSize: 12,
      fontWeight: 'normal',
      margin: 0,
      color: cqTheme.charcoal,
    },
  },
});

export default mui5Theme;
