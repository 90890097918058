import { types } from 'mobx-state-tree';

// STORES
import UiStore, { IUi } from './ui';
import LoginStore, { ILoginStore } from './login';
import UserStore, { IUserStore } from './user';
import RoleStore, { IRoleStore } from './role';
import AccessStore, { IAccessStore } from './access';
import SetStore, { ISetStore } from './set';
import FeesStore, { IFeesStore } from './fees';
import PaymentsStore, { IPaymentsStore } from './payments';
import CommonAreasStore, { ICommonAreasStore } from './commonAreas';
import ReservationsStore, { IReservationsStore } from './reservations';
import NotificationsStore, { INotificationsStore } from './notifications';

export const GlobalStore = types.model({
  uiStore: UiStore,
  loginStore: LoginStore,
  userStore: UserStore,
  roleStore: RoleStore,
  accessStore: AccessStore,
  setStore: SetStore,
  feesStore: FeesStore,
  paymentsStore: PaymentsStore,
  commonAreasStore: CommonAreasStore,
  reservationsStore: ReservationsStore,
  notificationsStore: NotificationsStore,
});

export interface IGlobalStore {
  ui: IUi;
  login: ILoginStore;
  user: IUserStore;
  role: IRoleStore;
  access: IAccessStore;
  set: ISetStore;
  fees: IFeesStore;
  payments: IPaymentsStore;
  commonAreas: ICommonAreasStore;
  reservation: IReservationsStore;
  notifications: INotificationsStore;
}

const store = GlobalStore.create({
  uiStore: UiStore.create({}),
  loginStore: LoginStore.create({}),
  userStore: UserStore.create({}),
  roleStore: RoleStore.create({}),
  accessStore: AccessStore.create({}),
  setStore: SetStore.create({}),
  feesStore: FeesStore.create({}),
  paymentsStore: PaymentsStore.create({}),
  commonAreasStore: CommonAreasStore.create({}),
  reservationsStore: ReservationsStore.create({}),
  notificationsStore: NotificationsStore.create({}),
});

export default store;
