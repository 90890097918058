import { Instance, types } from 'mobx-state-tree';

const PaymentsTotals = types.model({
  totalYearOverdueAmount: types.number,
  totalYearPayedAmount: types.number,
  totalYearUnitsWithOverdueStatus: types.number,
  totalYearUnitsUpToDate: types.number,
  totalOverdueAmount: types.number,
  totalUnitsWithOverdueStatus: types.number,
});

export interface IPaymentsTotals extends Instance<typeof PaymentsTotals> {}
export default PaymentsTotals;
