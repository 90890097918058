import { types, applySnapshot, getSnapshot, Instance, flow, cast } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import CommonArea from './models/CommonArea';

const log = debug('store:commonAreas:log');
const errorLog = debug('store:commonAreas:error');

const CommonAreasStore = types
  .model({
    flags: types.optional(Flags, {}),
    commonAreasList: types.optional(types.array(CommonArea), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    getCommonAreasList: flow(function* () {
      log('getCommonAreasList >>>>');
      try {
        self.flags.isFetchingGetCommonAreaList = true;
        const response = yield axios.get(endpoints.getCommonAreasList);
        log('getCommonAreasList >>>>', response);
        if (response.data.success) {
          self.commonAreasList = cast(response.data.commonAreasList);
        }
      } catch (err) {
        errorLog('getCommonAreasList >>>>', err);
      } finally {
        self.flags.isFetchingGetCommonAreaList = false;
      }
    }),
  }))
  .actions((self) => ({
    createCommonArea: flow(function* (data) {
      log('createCommonArea >>>>', data);
      try {
        self.flags.isFetchingCreateCommonArea = true;
        const response = yield axios.post(endpoints.createCommonArea, data);
        log('createCommonArea >>>>', response);
        if (response.data.success) {
          self.getCommonAreasList();
        }
        return response.data;
      } catch (err) {
        errorLog('createCommonArea >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateCommonArea = false;
      }
    }),
    updateCommonArea: flow(function* (data) {
      log('updateCommonArea >>>>', data);
      try {
        self.flags.isFetchingUpdateCommonArea = true;
        const response = yield axios.post(endpoints.updateCommonArea, data);
        log('updateCommonArea >>>>', response);
        if (response.data.success) {
          self.getCommonAreasList();
        }
        return response.data;
      } catch (err) {
        errorLog('updateCommonArea >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateCommonArea = false;
      }
    }),
    softDeleteCommonArea: flow(function* (commonAreaId) {
      log('softDeleteCommonArea >>>>', commonAreaId);
      try {
        self.flags.isFetchingSoftDeleteCommonArea = true;
        const response = yield axios.patch(endpoints.softDeleteCommonArea(commonAreaId));
        log('softDeleteCommonArea >>>>', response);
        if (response.data.success) {
          self.getCommonAreasList();
        }
        return response.data;
      } catch (err) {
        errorLog('softDeleteCommonArea >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingSoftDeleteCommonArea = false;
      }
    }),
  }));

export interface ICommonAreasStore extends Instance<typeof CommonAreasStore> {}

export default CommonAreasStore;
