import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import User from './models/User';

const log = debug('store:user:log');
const errorLog = debug('store:user:error');

const UserStore = types
  .model({
    userId: types.maybeNull(types.string),
    userData: types.optional(User, {}),
    flags: types.optional(Flags, {}),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getUserData: flow(function* () {
      const { loginStore } = getRoot(self);
      log('getUserData >>>>');
      try {
        self.flags.isFetchingUserData = true;
        const response = yield axios.get(endpoints.getUser);
        log('getUserDataResponse >>>>', response);
        if (response.data.success) {
          loginStore.flags.updateFlag('isAuthenticated', true);
          self.userData = cast(response.data.userData);
        }
      } catch (err) {
        errorLog('getUserDataError >>>>', err);
        loginStore.resetStore();
      }
    }),
    saveFcmToken: flow(function* (token: string) {
      log('saveFcmToken >>>>');
      try {
        const response = yield axios.post(endpoints.saveFcmToken, {
          fcmToken: token,
        });
        log('saveFcmToken >>>>', response);
      } catch (err) {
        errorLog('saveFcmToken >>>>', err);
      }
    }),
  }))
  .actions((self) => ({
    addAssociatedUser: flow(function* (data) {
      const { setStore } = getRoot(self);
      log('addAssociatedUser >>>>', data);
      self.flags.fetchingAddAssociatedUserSuccess = false;
      self.flags.fetchingAddAssociatedUserError = false;
      try {
        self.flags.isFetchingAddAssociatedUser = true;
        const response = yield axios.post(endpoints.addAssociatedUser, data);
        log('addAssociatedUser >>>>', response);
        if (response.data.success) {
          setStore.getSetUnitAssociatedUserList(data.unitId);
        }
        return response.data;
      } catch (err) {
        errorLog('addAssociatedUser >>>>', err);
        self.flags.fetchingAddAssociatedUserError = true;
        throw err;
      } finally {
        self.flags.isFetchingAddAssociatedUser = false;
      }
    }),
    resendAssociatedUserInvitation: flow(function* (data) {
      log('resendAssociatedUserInvitation >>>>', data);
      try {
        self.flags.isFetchingResendAssociatedUserInvitation = true;
        const response = yield axios.post(endpoints.resendAssociatedUserInvitation, data);
        log('resendAssociatedUserInvitation >>>>', response);
        return response.data;
      } catch (err) {
        errorLog('resendAssociatedUserInvitation >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingResendAssociatedUserInvitation = false;
      }
    }),
    cancelAssociatedUserInvitation: flow(function* (data) {
      const { setStore } = getRoot(self);
      log('cancelAssociatedUserInvitation >>>>', data);
      try {
        self.flags.isFetchingCancelAssociatedUserInvitation = true;
        const response = yield axios.post(endpoints.cancelAssociatedUserInvitation, {
          userId: data.userId,
        });
        log('cancelAssociatedUserInvitation >>>>', response);
        if (response.data.success) {
          setStore.getSetUnitAssociatedUserList(data.unitId);
        }
        return response.data;
      } catch (err) {
        errorLog('cancelAssociatedUserInvitation >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCancelAssociatedUserInvitation = false;
      }
    }),
    updateAssociatedUser: flow(function* (data) {
      const { setStore } = getRoot(self);
      log('updateAssociatedUser >>>>', data);
      try {
        self.flags.isFetchingUpdateAssociatedUser = true;
        const response = yield axios.post(endpoints.updateAssociatedUser, data);
        log('updateAssociatedUser >>>>', response);
        if (response.data.success) {
          setStore.getSetUnitAssociatedUserList(data.unitId);
        }
        return response.data;
      } catch (err) {
        errorLog('addAssociatedUser >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAssociatedUser = false;
      }
    }),
    softDeleteUser: flow(function* (data) {
      const { setStore } = getRoot(self);
      log('softDeleteUser >>>>', data);
      try {
        self.flags.isFetchingSoftDeleteUser = true;
        const response = yield axios.patch(endpoints.softDeleteUser(data.userId));
        log('updateAssociatedUser >>>>', response);
        if (response.data.success) {
          setStore.getSetUnitAssociatedUserList(data.unitId);
        }
        return response.data;
      } catch (err) {
        errorLog('softDeleteUser >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingSoftDeleteUser = false;
      }
    }),
  }));

export interface IUserStore extends Instance<typeof UserStore> {}

export default UserStore;
