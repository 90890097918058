const allowedActions = {
  SECURE_ACCESS_MODULE: 'secure_access_module',
  SECURE_ACCESS_CREATE_QR: 'secure_access_create_qr',
  SECURE_ACCESS_LIST: 'secure_access_list',
  SECURE_ACCESS_VIEW_QR: 'secure_access_view_qr',
  SECURE_ACCESS_SHARE_QR: 'secure_access_share_qr',
  SECURE_ACCESS_SOFT_DELETE: 'secure_access_soft_delete',
  SURVEILLANCE_MODULE: 'surveillance_module',
  SURVEILLANCE_READ_QR: 'surveillance_read_qr',
  HOME_MODULE: 'home_module',
  HOME_SET_ADMINISTRATION: 'home_set_administration',
  SET_ADMINISTRATION_ADD_ASSOCIATED_USERS: 'set_administration_add_associated_users',
  HOME_FEES_ADMINISTRATION: 'home_fees_administration',
  FEES_ADMINISTRATION_ADD_FEE: 'fees_administration_add_fee',
  HOME_PAYMENTS_ADMINISTRATION: 'home_payments_administration',
  HOME_COMMON_AREAS_ADMINISTRATION: 'home_common_areas_administration',
  COMMON_AREAS_REGISTER_AREA: 'common_areas_register_area',
  COMMON_AREAS_UPDATE_AREA: 'common_areas_update_area',
  COMMON_AREAS_SOFT_DELETE_AREA: 'common_areas_soft_delete_area',
  HOME_RESERVATIONS_ADMINISTRATION: 'home_reservations_administration',
  RESERVATIONS_APPROVE_RESERVATION: 'reservations_approve_reservation',
  RESERVATIONS_REJECT_RESERVATION: 'reservations_reject_reservation',
  SURVEILLANCE_SINGLE_CAMERA: 'surveillance_single_camera',
  SURVEILLANCE_DOUBLE_CAMERA: 'surveillance_double_camera',
  HOME_NOTIFICATIONS_SERVICES_ADMINISTRATION: 'home_notifications_services_administration',
} as const;

export { allowedActions };
