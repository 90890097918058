export const HASHES = [
  '#set',
  '#users',
  '#fees',
  '#payments',
  '#commonAreas',
  '#reservations',
  '#notifications-services',
];

export const ROUTES = {
  ADMIN: '/admin',
  LOGIN: '/login',
  ACCESS: '/access',
};
