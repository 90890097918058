import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AccessRoute from '@/components/AccessRoute';
import UnAuthRoute from '@/components/UnAuthRoute';
import AuthRoute from '@/components/AuthRoute';
import { allowedActions } from '@/libraries/accessManager/constants/allowedActions';

import { ROUTES } from '@/constants/routes';

import Login from '@/pages/Login';
import Administration from '@/pages/Administration';
import Access from '@/pages/Access';

const Router: FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN}>
        <UnAuthRoute>
          <Login />
        </UnAuthRoute>
      </Route>

      <Route path={ROUTES.ADMIN}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.HOME_MODULE}>
            <Administration />
          </AccessRoute>
        </AuthRoute>
      </Route>

      <Route path={ROUTES.ACCESS}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.SURVEILLANCE_MODULE}>
            <Access />
          </AccessRoute>
        </AuthRoute>
      </Route>

      <Redirect from="*" to={ROUTES.LOGIN} />
    </Switch>
  );
};

export default observer(Router);
