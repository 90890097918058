import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PermissionsProvider } from '@/libraries/accessManager/access/permissionsProvider';
import Layout from '@/components/Layouts';
import Router from '@/router';

import useStore from '@/hooks/useStore';
import { useNotificationListeners } from '@/hooks/useNotificationListeners';
import useGetUserData from '@/hooks/useGetUserData';
import useResizeUi from '@/hooks/useResizeUi';
import useDebugger from '@/hooks/useDebugger';
import useValidBrowser from '@/hooks/useValidBrowser';
import CustomBackdrop from '@/components/CustomBackdrop';

import UnSupportedBrowser from '@/pages/UnSupportedBrowser';

const App: FC = () => {
  const { loginStore, roleStore } = useStore();
  const { loadUserData, isLoadingUserData } = useGetUserData();
  const { isValidBrowser } = useValidBrowser();

  useNotificationListeners();
  useResizeUi();
  useDebugger();

  useEffect(() => {
    if (!loginStore.flags.isAuthenticated) {
      loadUserData();
    }
  }, [loginStore.flags.isAuthenticated]);

  if (isLoadingUserData) {
    return <CustomBackdrop loading />;
  }

  return (
    <>
      {isValidBrowser ? (
        <PermissionsProvider accessActions={roleStore.allowedActions} accessRole={roleStore.role}>
          <Layout>
            <Router />
          </Layout>
        </PermissionsProvider>
      ) : (
        <Layout>
          <UnSupportedBrowser />
        </Layout>
      )}
    </>
  );
};

export default observer(App);
