import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import useHasRoleAccess from '@/libraries/accessManager/access/useHasRoleAccess';
import { allowedRoles } from '@/libraries/accessManager/constants/allowedRoles';
import useStore from '@/hooks/useStore';
import { ROUTES } from '@/constants/routes';

interface UnAuthRouteProps {
  children: ReactNode;
}

const UnAuthRoute: FC<UnAuthRouteProps> = ({ children }) => {
  const { loginStore } = useStore();
  const isAuthenticated = loginStore.flags.isAuthenticated;
  const isSurveillance = useHasRoleAccess({ role: allowedRoles.SURVEILLANCE });

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return <Redirect from="*" to={!isSurveillance ? ROUTES.ADMIN : ROUTES.ACCESS} />;
};

export default observer(UnAuthRoute);
