import { Instance, types } from 'mobx-state-tree';

const PaymentListMonthItem = types.model({
  status: types.string,
  amount: types.number,
  canPay: types.boolean,
  paymentLinkId: types.string,
  paymentId: types.string,
});

const PaymentList = types.model({
  id: types.string,
  unitId: types.string,
  identifier: types.string,
  latestPayment: types.optional(types.string, ''),
  Enero: types.maybeNull(PaymentListMonthItem),
  Febrero: types.maybeNull(PaymentListMonthItem),
  Marzo: types.maybeNull(PaymentListMonthItem),
  Abril: types.maybeNull(PaymentListMonthItem),
  Mayo: types.maybeNull(PaymentListMonthItem),
  Junio: types.maybeNull(PaymentListMonthItem),
  Julio: types.maybeNull(PaymentListMonthItem),
  Agosto: types.maybeNull(PaymentListMonthItem),
  Septiembre: types.maybeNull(PaymentListMonthItem),
  Octubre: types.maybeNull(PaymentListMonthItem),
  Noviembre: types.maybeNull(PaymentListMonthItem),
  Diciembre: types.maybeNull(PaymentListMonthItem),
  totalYearUnitOverdueAmount: types.number,
  totalUnitOverdueAmount: types.maybeNull(types.number),
});

export interface IPaymentList extends Instance<typeof PaymentList> {}
export default PaymentList;
