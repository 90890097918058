import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import Fee from './models/Fee';

const log = debug('store:fees:log');
const errorLog = debug('store:fees:error');

const FeesStore = types
  .model({
    flags: types.optional(Flags, {}),
    feesList: types.optional(types.array(Fee), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    getFeesList: flow(function* () {
      log('getFeesList >>>>');
      try {
        self.flags.isFetchingGetFeesList = true;
        const response = yield axios.get(endpoints.getFeesList);
        log('getFeesList >>>>', response);
        if (response.data.success) {
          self.feesList = cast(response.data.feesList);
        }
      } catch (err) {
        errorLog('getFeesList >>>>', err);
      } finally {
        self.flags.isFetchingGetFeesList = false;
      }
    }),
  }))
  .actions((self) => ({
    createFee: flow(function* (data) {
      log('createFee >>>>', data);
      try {
        self.flags.isFetchingCreateFee = true;
        const response = yield axios.post(endpoints.createFee, data);
        log('createFee >>>>', response);
        if (response.data.success) {
          self.getFeesList();
        }
        return response.data;
      } catch (err) {
        errorLog('createFee >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateFee = false;
      }
    }),
    updateFee: flow(function* (data) {
      log('updateFee >>>>', data);
      try {
        self.flags.isFetchingUpdateFee = true;
        const response = yield axios.post(endpoints.updateFee, data);
        log('updateFee >>>>', response);
        if (response.data.success) {
          self.getFeesList();
        }
        return response.data;
      } catch (err) {
        errorLog('updateFee >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateFee = false;
      }
    }),
    softDeleteFee: flow(function* (feeId) {
      log('softDeleteFee >>>>', feeId);
      try {
        self.flags.isFetchingSoftDeleteFee = true;
        const response = yield axios.patch(endpoints.softDeleteFee(feeId));
        log('softDeleteFee >>>>', response);
        if (response.data.success) {
          self.getFeesList();
        }
        return response.data;
      } catch (err) {
        errorLog('softDeleteFee >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingSoftDeleteFee = false;
      }
    }),
    updateUnitFee: flow(function* (data) {
      const { setStore } = getRoot(self);
      log('updateUnitFee >>>>', data);
      try {
        self.flags.isFetchingUpdateUnitFee = true;
        const response = yield axios.post(endpoints.updateUnitFee, data);
        log('updateUnitFee >>>>', response);
        if (response.data.success) {
          setStore.getSetUnitsList();
        }
        return response.data;
      } catch (err) {
        errorLog('updateUnitFee >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateUnitFee = false;
      }
    }),
  }));

export interface IFeesStore extends Instance<typeof FeesStore> {}

export default FeesStore;
