import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyBh52Iu8doTJ2Fe9A2c9fdr1pm1X657Y7g',
  authDomain: 'cqurity-7f52f.firebaseapp.com',
  projectId: 'cqurity-7f52f',
  storageBucket: 'cqurity-7f52f.appspot.com',
  messagingSenderId: '962214681910',
  appId: '1:962214681910:web:d0a72e2f12bfc637e2e797',
  measurementId: 'G-K0MDPWSFWS',
};

initializeApp(config);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BFDDMfMW5r5fxgs_8BcZh8Sbc5lWTvkDC8eviwavTaD1Pjzm_szYK-9SdXVQJ0k1XVOj1iS8FKtsRXO8e83CnSY',
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
