import { useEffect, useState } from 'react';
import debug from 'debug';

export interface IUseValidBrowser {
  isValidBrowser: boolean;
}

const log = debug('hook:useValidBrowser:log');

function useValidBrowser(): IUseValidBrowser {
  const [isValidBrowser, setIsValidBrowser] = useState(true);
  const validateBrowser = async () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || !userAgent.match(/Chrome/i)) {
      // setIsValidBrowser(false);
    }
  };

  useEffect(() => {
    validateBrowser();
  }, []);

  return { isValidBrowser };
}

export default useValidBrowser;
