import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingGetSetData: false,
    isFetchingGetSetUnitsList: false,
    fetchGetSetUnitsListSuccess: false,
    fetchGetSetUnitsListError: false,
    isFetchingGetSetUnitAssociatedUserList: false,
    fetchGetSetUnitAssociatedUserListSuccess: false,
    fetchGetSetUnitAssociatedUserListError: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
