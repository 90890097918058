import { Instance, types } from 'mobx-state-tree';

const Log = types.model({
  logId: types.maybeNull(types.number),
  accessId: types.maybeNull(types.string),
  action: types.maybeNull(types.string),
  time: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

export interface ILog extends Instance<typeof Log> {}
export default Log;
