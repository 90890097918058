import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@material-ui/core/Grid';

import useStore from '@/hooks/useStore';
import useStyles from './styles';

export interface DefaultLayoutProps {
  children: ReactNode;
}

const logo = 'https://s3.us-east-2.amazonaws.com/assets.cqurity.io/logos/logoHorizontal.png';

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const css = useStyles();
  const { uiStore } = useStore();

  const mdWidth = uiStore.width <= 1100;

  const getLogo = () => (
    <Grid item xs={12} className={css.logo}>
      <img src={uiStore.width <= 1100 ? logo : logo} alt="CQurity" />
    </Grid>
  );

  return (
    <div className={css.root}>
      <div className={css.sidebar}>
        <Grid container className={css.header}>
          {getLogo()}
        </Grid>
      </div>

      <div className={css.content} data-testid="frame-content">
        <Grid container className={css.contentHeader} id="contentHeader">
          <div className={css.contentHeaderPad}>{mdWidth && getLogo()}</div>
        </Grid>
        <Grid container className={css.containerBody}>
          {children}
        </Grid>
      </div>
    </div>
  );
};

export default observer(DefaultLayout);
