import { types, applySnapshot, getSnapshot, Instance, flow, cast } from 'mobx-state-tree';
import _ from 'underscore';
import { toJS } from 'mobx';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import Set from './models/Set';
import Unit from './models/Unit';
import AssociatedUser from './models/AssociatedUser';

const log = debug('store:set:log');
const errorLog = debug('store:set:error');

const SetStore = types
  .model({
    setInfo: types.optional(Set, {}),
    flags: types.optional(Flags, {}),
    unitsList: types.optional(types.array(Unit), []),
    associatedUserList: types.optional(types.array(AssociatedUser), []),
    preFilteredAssociatedUserList: types.optional(types.array(AssociatedUser), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .views((self) => ({
    get getUnitsList() {
      return [...self.unitsList];
    },
  }))
  .actions((self) => ({
    getSetData: flow(function* () {
      log('getSetData >>>>');
      try {
        self.flags.isFetchingGetSetData = true;
        const response = yield axios.get(endpoints.getSet);
        log('getSetDataResponse >>>>', response);
        if (response.data.success) {
          self.setInfo = cast(response.data.setData);
        }
      } catch (err) {
        errorLog('getSetData >>>>', err);
      } finally {
        self.flags.isFetchingGetSetData = false;
      }
    }),
    getSetUnitsList: flow(function* () {
      log('getSetUnitsList >>>>');
      try {
        self.flags.isFetchingGetSetUnitsList = true;
        const response = yield axios.get(endpoints.getSetUnitsList);
        log('getSetUnitsList >>>>', response);
        if (response.data.success) {
          self.unitsList = cast(response.data.unitList);
        }
      } catch (err) {
        errorLog('getSetAccessList >>>>', err);
      } finally {
        self.flags.isFetchingGetSetUnitsList = false;
      }
    }),
    getSetUnitAssociatedUserList: flow(function* (unitId: string) {
      log('getSetUnitAssociatedUserList >>>>', unitId);
      self.flags.fetchGetSetUnitAssociatedUserListSuccess = false;
      self.flags.fetchGetSetUnitAssociatedUserListError = false;
      try {
        self.flags.isFetchingGetSetUnitAssociatedUserList = true;
        const response = yield axios.get(endpoints.getSetUnitAssociatedUserList(unitId));
        log('getSetUnitAssociatedUserList >>>>', response);
        if (response.data.success) {
          self.flags.fetchGetSetUnitAssociatedUserListSuccess = true;
          self.associatedUserList = cast(
            _.sortBy(response.data.associatedUserList, 'emailVerified'),
          );
        }
      } catch (err) {
        self.flags.fetchGetSetUnitAssociatedUserListError = true;
        errorLog('getSetUnitAssociatedUserList >>>>', err);
      } finally {
        self.flags.isFetchingGetSetUnitAssociatedUserList = false;
      }
    }),
    filterAssociatedUserList: (value: string) => {
      if (value === '' && Object.keys(toJS(self.preFilteredAssociatedUserList)).length > 0) {
        self.associatedUserList = cast(toJS(self.preFilteredAssociatedUserList));
        self.preFilteredAssociatedUserList = cast([]);
      }

      if (Object.keys(toJS(self.preFilteredAssociatedUserList)).length === 0) {
        self.preFilteredAssociatedUserList = cast(toJS(self.associatedUserList));
      }

      const filtered = toJS(self.associatedUserList).filter(function (v) {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v.userFullName.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          v.userEmail.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      self.associatedUserList = cast(filtered);
    },
  }));

export interface ISetStore extends Instance<typeof SetStore> {}

export default SetStore;
