import { useCallback, useEffect } from 'react';
import debug from 'debug';

import { FireBaseCookie } from '@/utils/authCookie';
import { requestForToken } from '@/config/firebaseConfig';
import useStore from '@/hooks/useStore';

export interface IUseGetUserData {
  loadUserData: () => void;
  getFirebaseToken: () => void;
  isLoadingUserData: boolean;
}

const log = debug('hook:useGetUserData:log');
const error = debug('hook:useGetUserData:error');

function useGetUserData(): IUseGetUserData {
  const { userStore, roleStore, loginStore, setStore } = useStore();
  const isLoadingUserData = userStore.flags.isFetchingUserData;

  const loadUserData = useCallback(async () => {
    try {
      await userStore.getUserData();
      await setStore.getSetData();
      await roleStore.getRoleData();
    } catch {
      // empty catch for now
    }
  }, []);

  const getFirebaseToken = async () => {
    const fbToken = FireBaseCookie.getCookie();
    log('getFirebaseToken >>> fbToken: ' + fbToken);
    if (!fbToken) {
      log('getFirebaseToken >>> Cookie fb token is not present, proceed to request token');
      requestForToken()
        .then((currentToken) => {
          if (!currentToken) {
            log(
              'getFirebaseToken >>> No registration token available. Request permission to generate one.',
            );
          } else {
            FireBaseCookie.setCookie(currentToken);
            userStore.saveFcmToken(currentToken);
            log('getFirebaseToken >>> Success >>> New Token for client: ' + currentToken);
          }
        })
        .catch((err) => {
          error('getFirebaseToken >>> Error >>> An error occurred while retrieving token, ', err);
        });
    }
  };

  useEffect(() => {
    if (loginStore.flags.isAuthenticated && userStore.userId !== '') {
      log('useEffect >>> getFirebaseToken');
      getFirebaseToken();
    }
  }, [loginStore.flags.isAuthenticated, userStore.userId]);

  return { loadUserData, isLoadingUserData, getFirebaseToken };
}

export default useGetUserData;
