import React, { FC, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import CustomBackdrop from '@/components/CustomBackdrop';

import { AuthCookie } from '@/utils/authCookie';
import useGetUserData from '@/hooks/useGetUserData';
import useStore from '@/hooks/useStore';
import { ROUTES } from '@/constants/routes';

interface AuthRouteProps {
  children: ReactNode;
}

const AuthRoute: FC<AuthRouteProps> = ({ children }) => {
  const { loginStore, userStore } = useStore();
  const { loadUserData, isLoadingUserData } = useGetUserData();

  const isAuthenticated = loginStore.flags.isAuthenticated;
  const hasToken = AuthCookie.getCookie() !== '';
  console.log(isAuthenticated);
  console.log(userStore.userData.userId);
  useEffect(() => {
    if (isAuthenticated && userStore.userData.userId == '') {
      loadUserData();
    }
  }, [isAuthenticated, userStore.userData.userId]);

  if (isLoadingUserData) {
    return <CustomBackdrop loading />;
  }

  if (isAuthenticated) {
    console.log('entro aqui');
    return <>{children}</>;
  }

  if (loginStore.flags.isAuthenticated === null || !hasToken) {
    return <Redirect from="*" to={ROUTES.LOGIN} />;
  }

  return <CustomBackdrop loading={false} />;
};

export default observer(AuthRoute);
