import { Instance, types } from 'mobx-state-tree';

const Payment = types.model({
  paymentId: types.string,
  paymentUserId: types.string,
  paymentUnitId: types.string,
  paymentLinkId: types.string,
  paymentMethod: types.string,
  amount: types.string,
  concept: types.string,
  payedMonth: types.string,
  payedYear: types.string,
  createdAt: types.string,
  payedAt: types.string,
  expiredAt: types.string,
  paymentLinkUrl: types.string,
  orderId: types.string,
  cardName: types.string,
  cardExpMonth: types.string,
  cardExpYear: types.string,
  cardType: types.string,
  cardLast4: types.string,
  cardBrand: types.string,
  serviceName: types.string,
  serviceReference: types.string,
  status: types.string,
});

export interface IPayment extends Instance<typeof Payment> {}
export default Payment;
