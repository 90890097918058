import { FC, SVGProps, ChangeEvent } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, withStyles } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTabStyles, useTabContainerStyles } from './styles';

interface ISidebarItem {
  disabled?: boolean;
  title: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  link: string;
  tooltip?: string;
  itemId?: string;
}

export interface ISidebarNavigation {
  items: ISidebarItem[];
  selected: string;
  onChange: (link: string) => void;
}

function a11yProps(index: number) {
  return {
    id: `sidebar-tab-${index}`,
    'aria-controls': `sidebar-tabpanel-${index}`,
  };
}

export const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.cqTheme.cotton}`,
      boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%)`,
    },
    color: theme.cqTheme.cotton,
  },
  tooltip: {
    boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%),
      0px 3px 14px 2px rgb(0 0 0 / 12%)`,
    backgroundColor: theme.cqTheme.cotton,
    borderRadius: 8,
    padding: 10,
    fontWeight: 400,
    color: theme.cqTheme.charcoal,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

const SidebarNavigation: FC<ISidebarNavigation> = ({ items, selected, onChange }) => {
  const tabCss = useTabStyles();
  const tabContainerCss = useTabContainerStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery('(max-width: 450px)');

  const handleChange = (_: ChangeEvent<Record<string, unknown>>, newValue: string) =>
    onChange(newValue);

  return (
    <Tabs
      orientation={isSmallScreen ? 'horizontal' : 'vertical'}
      variant={isSmallScreen && !isMobileScreen ? 'fullWidth' : 'scrollable'}
      value={selected}
      onChange={handleChange}
      classes={tabContainerCss}
      centered={isMobileScreen}
      aria-label="Sidebar Menu"
      scrollButtons="auto"
      data-testid="sidebar-tabs-menu"
    >
      {items.map(({ icon: Icon, ...item }, idx) => (
        <Tab
          style={{ pointerEvents: 'auto' }}
          key={`sidebarItem-${idx}`}
          data-testid={`sidebarItem-${idx}`}
          icon={<Icon />}
          label={
            item.tooltip ? (
              <HtmlTooltip arrow title={item.tooltip} placement="right">
                <span data-testid={`spanTooltip-${idx}`}>{item.title}</span>
              </HtmlTooltip>
            ) : (
              <span>{item.title}</span>
            )
          }
          {...a11yProps(idx)}
          value={item.link}
          classes={tabCss}
          disabled={item.disabled}
          id={item.itemId}
        />
      ))}
    </Tabs>
  );
};

export default SidebarNavigation;
