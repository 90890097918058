export default {
  login: '/login',
  getUser: '/user',
  getSet: '/set',
  getRole: '/role',
  validateAccess: (accessId: string, action: string): string =>
    `/access/validate/${accessId}?action=${action}`,
  getSetAccessList: '/access/set/list',
  saveFcmToken: '/notifications/token/create',
  getSetAccessLogList: '/access/set/log/list',
  getSetUnitsList: '/set/units/list',
  getSetUnitAssociatedUserList: (unitId: string): string => `/set/unit/${unitId}/users/list`,
  addAssociatedUser: '/user/create/associated',
  resendAssociatedUserInvitation: '/user/associated/resend-invitation',
  cancelAssociatedUserInvitation: '/user/associated/cancel-invitation',
  updateAssociatedUser: '/user/update/associated',
  softDeleteUser: (userId: string): string => `/user/soft-delete/${userId}`,
  getFeesList: '/fees/list',
  createFee: '/fees/create',
  updateFee: '/fees/update',
  softDeleteFee: (feeId: string): string => `/fees/soft-delete/${feeId}`,
  updateUnitFee: '/fees/update/unit',
  getPaymentsList: (year: string): string => `/payments/${year}/set/list`,
  getPaymentDetail: (paymentId: string): string => `/payments/${paymentId}`,
  registerCashPayment: '/payments/register',
  getCommonAreasList: '/common-areas/list',
  createCommonArea: '/common-areas/create',
  updateCommonArea: '/common-areas/update',
  softDeleteCommonArea: (commonAreaId: string): string =>
    `/common-areas/soft-delete/${commonAreaId}`,
  getReservationsSetList: '/reservations/set/list',
  approveReservation: (reservationId: string): string => `/reservations/approve/${reservationId}`,
  rejectReservation: (reservationId: string): string => `/reservations/reject/${reservationId}`,
  getSetNotificationsServicesList: '/notifications/services/set/list',
  saveSetNotificationService: '/notifications/services/set/save',
};
