import { Instance, types } from 'mobx-state-tree';

const Service = types.model({
  serviceId: types.string,
  notificationServiceId: types.string,
  name: types.string,
  selected: types.boolean,
});

export interface IService extends Instance<typeof Service> {}
export default Service;
