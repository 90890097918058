import { Instance, types } from 'mobx-state-tree';

const Access = types.model({
  accessId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  setId: types.maybeNull(types.string),
  guestId: types.maybeNull(types.string),
  guestName: types.maybeNull(types.string),
  guestEmail: types.maybeNull(types.string),
  guestPhone: types.maybeNull(types.string),
  accessClass: types.maybeNull(types.string),
  accessClassFormatted: types.maybeNull(types.string),
  accessMethod: types.maybeNull(types.string),
  accessType: types.maybeNull(types.string),
  accessTypeFormatted: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  validUntil: types.maybeNull(types.string),
  accessValid: types.maybeNull(types.number),
  accessStatus: types.maybeNull(types.string),
  hostFirstName: types.maybeNull(types.string),
  hostMiddleName: types.maybeNull(types.string),
  hostLastName: types.maybeNull(types.string),
  hostFullName: types.maybeNull(types.string),
  hostSetName: types.maybeNull(types.string),
  hostSetUnitId: types.maybeNull(types.string),
  hostSetUnitIdentifier: types.maybeNull(types.string),
  hostSetUnitIdentifierDisplay: types.maybeNull(types.string),
  lastAction: types.maybeNull(types.string),
});

export interface IAccess extends Instance<typeof Access> {}
export default Access;
