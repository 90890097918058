import { types, applySnapshot, getSnapshot, Instance, flow, getRoot } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import { AuthCookie, FireBaseCookie } from '@/utils/authCookie';
import endpoints from '@/config/endpoints';

import Flags from './models/Flags';

const log = debug('store:login:log');
const errorLog = debug('store:login:error');

const LoginStore = types
  .model({
    flags: types.optional(Flags, {}),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    logoutReset: async () => {
      const { userStore, roleStore } = getRoot(self);
      AuthCookie.deleteCookie();
      FireBaseCookie.deleteCookie();
      self.resetStore();
      userStore.logoutReset();
      roleStore.logoutReset();
    },
  }))
  .actions((self) => ({
    login: flow(function* ({ email, pass }) {
      const { userStore, roleStore, setStore } = getRoot(self);
      self.flags.isFetchingLoginError = false;
      log('login >>>>', email, pass);
      try {
        self.flags.isFetchingLogin = true;
        const response = yield axios.post(endpoints.login, {
          email,
          pass,
        });
        log('loginResponse >>>>', response);
        if (response.data.success) {
          const { token } = response.data.auth;
          log('token >>>>', token);
          AuthCookie.setCookie(token);
          self.flags.isAuthenticated = true;
          yield userStore.getUserData();
          yield setStore.getSetData();
          yield roleStore.getRoleData();
        }
      } catch (err) {
        errorLog('loginError >>>>', err);
        self.flags.isFetchingLoginError = true;
        throw err;
      } finally {
        self.flags.isFetchingLogin = false;
      }
    }),
  }));

export interface ILoginStore extends Instance<typeof LoginStore> {}

export default LoginStore;
