import { types, applySnapshot, getSnapshot, Instance, flow, cast } from 'mobx-state-tree';
import debug from 'debug';
import axios from 'axios';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import Service from './models/Service';

const log = debug('store:notifications:log');
const errorLog = debug('store:notifications:error');

const NotificationsStore = types
  .model({
    flags: types.optional(Flags, {}),
    servicesList: types.optional(types.array(Service), []),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    getSetNotificationsServicesList: flow(function* () {
      log('getSetNotificationsServicesList >>>>');
      try {
        self.flags.isFetchingGetSetNotificationsServicesList = true;
        const response = yield axios.get(endpoints.getSetNotificationsServicesList);
        log('getSetNotificationsServicesList >>>>', response);
        if (response.data.success) {
          self.servicesList = cast(response.data.servicesList);
        }
      } catch (err) {
        errorLog('getSetNotificationsServicesList >>>>', err);
      } finally {
        self.flags.isFetchingGetSetNotificationsServicesList = false;
      }
    }),
  }))
  .actions((self) => ({
    saveSetNotificationService: flow(function* (data) {
      log('saveSetNotificationService >>>>', data);
      try {
        self.flags.isFetchingSaveSetNotificationService = true;
        const response = yield axios.post(endpoints.saveSetNotificationService, data);
        log('saveSetNotificationService >>>>', response);
        return response.data;
      } catch (err) {
        self.getSetNotificationsServicesList();
        errorLog('saveSetNotificationService >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingSaveSetNotificationService = false;
      }
    }),
  }));

export interface INotificationsStore extends Instance<typeof NotificationsStore> {}

export default NotificationsStore;
